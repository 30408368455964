<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
  >
    <b-modal
      id="modal-my-availibility"
      v-model="showModal"
      size="md"
      no-close-on-backdrop
      centered
      cancel-variant="outline-secondary"
      @hidden="onHidden"
      hide-footer
    >
      <b-form>
        <b-row class="mb-1">
          <b-col
            sm="12"
            class="mb-3"
          >
            <h4 class="text-center">
              <span v-if="!isInUnavailabilityPeriod">Available</span><span v-if="isInUnavailabilityPeriod">Unavailable</span> for Match Requests
            </h4>
          </b-col>
          <b-col
            sm="12"
          >
            <b-row>
              <b-col
                sm="12"
                class="d-flex justify-content-center text-muted"
              >
                <feather-icon
                  class="mr-1"
                  icon="ClockIcon"
                  size="50"
                />
              </b-col>  
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-center"
          >
            <p
              v-if="!isInUnavailabilityPeriod"
              class="text-center"
            >
              You will only receive Match Requests when you indicate you are available. Your status is now Available unless you change it.
            </p>
            <p
              v-if="isInUnavailabilityPeriod"
              class="text-center"
            >
              You will not receive Match Requests when you indicate you are unavailable. 
              You are now Unavailable. Your status will change back to Available on the date you set as the end date.
            </p>
          </b-col>
          <b-col
            sm="12"
            class="d-flex justify-content-center flex-fill"
          >
            <validation-provider
              v-slot="validationContext"
              ref="withDrawRequestMessage"
              rules="required"
              name="Withdraw Request Message"
              tag="div"
              class="width"
              style="margin: 0 auto;width: auto;"
            >
              <p class="d-inline-block mr-2">
                <small>Unavailable</small>
              </p>
              <b-form-checkbox
                v-model="available"
                name="check-button"
                switch
                class="d-inline-block"
              />
              <p class="d-inline-block ml-1">
                <small>Available</small>
              </p>
              <b-form-invalid-feedback
                :state="validationContext.errors.length === 0"
              >
                {{
                  validationContext.errors[0]
                }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row> 
        <b-row
          v-if="!available"
          class="mt-2"
        >
          <b-col sm="6">
            <p class="text-center">
              Unavailable From
            </p>
            <flat-pickr
              id="unavailable-from"
              v-model="unavailableFromDate"
              class="form-control"
              placeholder="Select a Date"
              :config="fromConfig"
            />
          </b-col>
          <b-col sm="6">
            <p class="text-center">
              Unavailable To
            </p>
            <flat-pickr
              id="unavailable-to"
              v-model="unavailableToDate"
              class="form-control"
              placeholder="Select a Date"
              :config="toConfig"
            />
          </b-col>
        </b-row>           
      </b-form>
      <div class="d-flex justify-content-center mt-2">
        <b-button
          size="md"
          class="mx-auto"
          variant="primary"
          type="submit"
          @click="handleSubmit(updateAvailbility)"
        >
          <b-spinner
            v-if="isUpdating" 
            small
          />
          <span
            v-if="isUpdating"
          > Updating...</span>
          <span v-else>
            <feather-icon
              class="mr-1"
              icon="SaveIcon"
              size="16"
            />Save</span>
        </b-button>
      </div>
    </b-modal>
  </validation-observer>
</template>
            
<script>
import {
  BRow,
  BCol,
  BForm,
  BModal,
  BButton,
  VBToggle,
  VBTooltip, 
  BFormInvalidFeedback,
  BSpinner, 
  BFormCheckbox
} from "bootstrap-vue";

import { mapGetters } from 'vuex';
import { userRoles } from '@models';
import { authService } from '@services';
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { PRIMARY_DATE_FORMAT } from "@/libs/utils";
//eslint-disable-next-line
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";

export default {
  name: "MyAvailibility",
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormInvalidFeedback,
    BSpinner,
    BFormCheckbox,
    flatPickr
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  props: {
    user: {
      type: Object,
      default:() => {},
      required: true
    }
  },
  data() {
    return {
      showModal: false, 
      isLoading: false,
      available: true,
      isUpdating: false,
      unavailableToDate: undefined,
      unavailableFromDate: undefined
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId']),
    ...mapGetters('participants', ['mentorDisplay', 'menteeDisplay']),
    ...mapGetters('profile', ['userProgramSettings']),
    isInUnavailabilityPeriod() {
      if (!this.unavailableFromDate || !this.unavailableToDate) {
        return false;
      }
      this.$store.commit("profile/SET_IS_USER_IN_AVAILBILITY", this.isInPeriod(this.unavailableFromDate, this.unavailableToDate));
      return this.isInPeriod(this.unavailableFromDate, this.unavailableToDate);
    }
  },
  watch: {
    unavailableFromDate(n) {
      if(n) {
        this.toConfig = {
          ...this.toConfig,
          minDate: n
        };
      }
    },
    user: {
      handler(n) {
        if(n) {
          this.updateUserDates();
        }
      },
      deep: true,
      immmediate: true
    }
  },
  created() {
    this.updateUserDates();
  },
  methods: {
    updateUserDates() {
      if(this.userProgramSettings && this.userProgramSettings.length > 0) {
        const programSettings = this.userProgramSettings.filter(s => s.program_id === this.defaultProgramId);
        if(programSettings.length > 0) {
          this.unavailableFromDate = programSettings[0].unavailable_start;
          this.unavailableToDate = programSettings[0].unavailable_end;
          if(this.unavailableFromDate  || this.unavailableFromDate ) {
            this.available = false;
          };
        }
      }
    },
    show() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.showModal = false;
      this.$emit('clear');
    },
    userInitials(user) {
      if(user.first_name && user.last_name) {
        const firstInt = Array.from(user.first_name)[0];
        const lastInt = Array.from(user.last_name)[0];
        return `${firstInt}${lastInt}`;
      }
    },
    userProfileImage (user) {
      return user?.profile_image?.url || '';
    },
    isInPeriod(start, end) {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const startParts = start.split(/-/);
      const endParts = end.split(/-/);
      const startDate = new Date(startParts[0], startParts[1] - 1, startParts[2]);
      const endDate = new Date(endParts[0], endParts[1] - 1, endParts[2]);
      return startDate <= now && endDate >= now;
    },
    async updateAvailbility() {
      try {
        this.isUpdating = true;
        const updateRequest = this.updateRequest();
        const response = await authService.updateUserProfile(updateRequest);
        const { data } = response;
        this.$store.commit("profile/SET_USER_PROFILE", data);
        this.$toast(makeSuccessToast("Avalibility Updated."));
        this.clearForm();
      } catch (e) {
        const {data} = e.response;
        console.log(e);
        this.$toast(makeErrorToast(data?.message));
      } finally {
        this.isUpdating = false;
      }
    },
    updateRequest() {
      if(!this.available) {
        return {
          user_program_settings: [{
            program_id: this.defaultProgramId,
            unavailable_start:  this.unavailableFromDate,
            unavailable_end: this.unavailableToDate
          }]
        };
      }
      return {
        user_program_settings: [{
          program_id: this.defaultProgramId,
          unavailable_start:  '',
          unavailable_end: ''
        }]
      };
    }
  },
  setup() {
    const fromConfig = {
      defaultDate: 'today',
      enableTime: false,
      dateFormat: 'Y-m-d', 
      allowInput: true,
      altInput: true,
      altFormat: PRIMARY_DATE_FORMAT,
      minDate: 'today'
    };
    const toConfig = {
      defaultDate: 'today',
      enableTime: false,
      dateFormat: 'Y-m-d', 
      allowInput: true,
      altInput: true,
      altFormat: PRIMARY_DATE_FORMAT,
    };
    return {
      fromConfig,
      toConfig,
      userRoles
    };
  },
};
</script>
<style lang="scss">
#modal-my-availibility___BV_modal_header_ {
    background: none !important;
}
</style>